.contact-image img{
    width:100%;
    height:100%;
}
.contact-form-wrapper{
    /* border:1px solid black; */
    /* height:500px; */
    margin-top:50px;
}
.contact-input{
    width:100%;
    padding:15px;
    border-radius:7px;
    border:none;
    color:#B39999;
    background-color:#F7F7F7;
}
.contact-input::placeholder{
    color:#B39999;
}
.contact-input:focus-visible{
    box-shadow:none;
    border:none;
    outline:none;
}

.contact-btn button{
    font-size: 14px;
    border-radius: 25px;
    padding: 12px 25px;
    background-color: #fba70b;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    padding:12px 55px;
}
.contact-btn button:hover{
    border:1px solid #fba70b;
}


/* footer css */

.footer-wrapper{
    background:#000;
    color:#fff;
    font-family:"Nunito Sans";
    padding:15px;
    /* display:flex; */
    align-items:center;
    justify-content:center;
    
}

.contact-info{
    position:relative;
}
.contact-info p{
    font-family:"Poppins", sans-serif;
    font-size:16px;
}
.appearance-none::-webkit-outer-spin-button,
.appearance-none::-webkit-inner-spin-button{
     -webkit-appearance: none;
}
.contact-info::after{
    content:"";
    position:absolute;
    border-bottom:1px solid #fff;
    left:50%;
    transform:translateX(-50%);
    bottom:-20px;
    width:60%;
}