/* Modal Container */
.modal {
  display: none; /* Hide modal by default */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}

/* Display the modal when isOpen is true */
.modal.active {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe; /* White background */
  padding: 20px;
  border: 1px solid #888; /* Gray border */
  width: 90%;
  max-width: 600px; /* Maximum width for modal */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow */
}

/* Close Button */
.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 8px;
  right: 16px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Modal Content Styles */
.modal h2 {
  margin-top: 0; /* Remove top margin for heading */
}

.modal p {
  margin-bottom: 10px; /* Add bottom margin for paragraphs */
}

/* Custom Button */
.modal button {
  padding: 10px 20px;
  background-color: #007bff; /* Blue button */
  color: #fff; /* White text */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  /* Adjust modal content width for smaller devices */
  .modal-content {
    width: 95%;
    max-width: 400px;
  }
}
