/* .banner-wrapper{
   margin-top:50px;
}

.banner-text{
    font-size:48px;
    font-weight:700;
    font-family:"Nunito Sans";
}

.banner-subtext{
    font-weight: 300;
    font-size: 17px;
    color: #7a7a7a;
    line-height: 30px;
    letter-spacing: 0.25px;
    margin-bottom: 40px;
    font-family:"Poppins", sans-serif;
}
.banner-btn button{
    font-size: 14px;
    border-radius: 25px;
    padding: 15px 25px;
    background-color: #fba70b;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    padding:15px 40px;
}
.banner-btn button:hover{
    border:1px solid #fba70b;
}
.banner-right-img img{
    height:100%;
    width:100%;
    /* border:1px solid black; 
}*/


.banner-wrapper {
    margin-top: 50px;
  }
  
  .banner-text {
    font-size: 2.5rem; /* Changed from 48px for better responsiveness */
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
  }
  
  .banner-subtext {
    font-weight: 300;
    font-size: 1rem; /* Changed from 17px for better responsiveness */
    color: #7a7a7a;
    line-height: 1.75; /* Changed from 30px for better responsiveness */
    letter-spacing: 0.25px;
    margin-bottom: 40px;
    font-family: "Poppins", sans-serif;
  }
  
  .banner-btn button {
    font-size: 14px;
    border-radius: 25px;
    padding: 15px 40px; /* Padding combined to a single declaration */
    background-color: #fba70b;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  .banner-btn button:hover {
    border: 1px solid #fba70b;
  }
  
  .banner-right-img img {
    width: 100%;
    height: auto; /* Ensures image maintains aspect ratio */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .banner-text {
      font-size: 2rem; /* Smaller font size for tablets */
    }
  
    .banner-subtext {
      font-size: 0.9rem; /* Smaller font size for tablets */
    }
  
    .banner-btn button {
      padding: 10px 30px; /* Adjust button padding for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .banner-text {
      font-size: 1.5rem; /* Smaller font size for mobile */
    }
  
    .banner-subtext {
      font-size: 0.8rem; /* Smaller font size for mobile */
    }
  
    .banner-btn button {
      padding: 10px 20px; /* Adjust button padding for smaller screens */
    }
  }
  