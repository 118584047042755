.service-title{
    font-size:36px;
    font-weight:600;
    font-family:"Nunito Sans";
    display:flex;
    justify-content:center;
    margin-top:30px;
    position:relative;
    
}
.service-title::after{
    content:"";
    position:absolute;
    left:50%;
    bottom:-5px;
    transform:translateX(-50%);
    height:2px;
    width:50px;
    background:#fba70b;
}

.service-box-container{
    margin:70px 0;
}

.service-box{
    height:300px;
    width:100%;
    /* border:1px solid #ced4da; */
    /* box-shadow: -20px 2px 15px #ced4da; */
    box-shadow: 0px 0px 15px #ced4da;
    transition: 0.3s ease;
    border-radius:2px;
    position:relative;
    padding:8px;    
}
.service-box:hover{
    transform:translateY(-10px);
}

.service-box-image{
    height:50px;
    width:50px;   
}
.service-box-text{
    font-family:"Poppins", sans-serif;
    font-weight:300;
    font-size:18px;
}
.service-desc-text{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4; 
    /* max-height: 3em; */
    font-size:14px;
    font-family:"Poppins", sans-serif;
    color:#7a7a7a;
}
.service-box-btn{
    position:absolute;
    bottom:15px;
    left:50%;
    transform:translateX(-50%);
    padding:10px 10px;
    width:90%;
    color:#000;
    border:1px solid #fba70b;
    
    /* background:#fba70b; */
}

.service-box-btn:hover{
    border:1px solid #fba70b;
}