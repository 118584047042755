/* /* .nav-item{
    background-color:red;
} */
/* .navbar{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:999;
}
.header-main-wrapper{
    padding: 20px 10px;
}
.nav-item{
    padding-Y:0 !important;
}
.navbar-nav{
    --bs-nav-link-padding-y:0px !important;
}
.nav-item a{
    font-family:"Nunito Sans" !important;
    font-size:18px;
    font-weight:400;
    margin-right:40px;
    text-decoration:none !important;
    color:#000 !important;
} */
/* .nav-item a.active{
    border-bottom:1px solid red;
} */
/* .nav-link{
    font-family:"Nunito Sans";
    font-size:18px;
    font-weight:400;
    margin-right:40px;
}
.nav-link{
    position:relative;
} */
/* .nav-link.active::before{
    content:"";
    position:absolute;
    border-bottom:1px solid black;
    left:8px;
    bottom:6px;
    width:30px;
    height:2px;
    background:#fba70b;
} */

/* .navbar-expand-lg .navbar-nav .nav-link{
    padding-left:0 !important;
    padding-right:0 !important;
}  */

/* new css*/
/* Ensure the navbar is fixed at the top */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #fff; /* Add a background color for better readability */
  }
  
  .header-main-wrapper {
    padding: 20px 10px;
  }
  
  .nav-item {
    padding-y: 0 !important;
  }
  
  .navbar-nav {
    --bs-nav-link-padding-y: 0px !important;
  }
  
  .nav-item a {
    font-family: "Nunito Sans" !important;
    font-size: 18px;
    font-weight: 400;
    margin-right: 40px;
    text-decoration: none !important;
    color: #000 !important;
  }
  
  /* Active link styling */
  .nav-link.active::before {
    content: "";
    position: absolute;
    border-bottom: 1px solid #fba70b; /* Add a bottom border to the active link */
    left: 0;
    bottom: -5px;
    width: 100%;
  }
  
  .nav-link {
    font-family: "Nunito Sans";
    font-size: 18px;
    font-weight: 400;
    margin-right: 40px;
    position: relative; /* Position relative for the active link underline */
  }
  
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 992px) {
    .navbar-nav {
      text-align: center;
      padding: 0;
    }
  
    .nav-item {
      margin: 0;
    }
  
    .nav-item a {
      margin-right: 0;
      padding: 10px 0;
    }
  
    .navbar-collapse {
      justify-content: center;
    }
  
    .header-main-wrapper {
      padding: 15px 5px;
    }
  }
  
  @media (max-width: 576px) {
    .navbar-brand {
      font-size: 1.25rem; /* Adjust brand font size for mobile */
    }
  
    .nav-item a {
      font-size: 16px;
    }
  
    .header-main-wrapper {
      padding: 10px 5px;
    }
  }
  